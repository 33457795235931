import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { As, Button, ButtonProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { MdAdd } from 'react-icons/md';

type DeviceAdaptiveActionButtonProps = {
  onClick: () => void;
  text: string;
  isDisabled?: boolean;
  desktopProps?: ButtonProps;
  mobileProps?: ButtonProps;
  as?: As;
};

const DeviceAdaptiveActionButton = forwardRef<HTMLButtonElement, DeviceAdaptiveActionButtonProps>(
  function DeviceAdaptiveActionButton(
    { onClick, text, isDisabled, desktopProps, mobileProps, as }: DeviceAdaptiveActionButtonProps,
    ref
  ) {
    const { isDesktop } = useScreenInfos();

    return isDesktop ? (
      <Button
        as={as}
        ref={ref}
        leftIcon={<MdAdd />}
        variant='solid'
        colorScheme='primary'
        size='sm'
        onClick={() => onClick()}
        my={1}
        mx={1}
        zIndex={1}
        isDisabled={!!isDisabled}
        {...desktopProps}
      >
        {text}
      </Button>
    ) : (
      <Button
        as={as}
        ref={ref}
        leftIcon={<MdAdd />}
        variant='extendedFAB'
        size='lg'
        onClick={() => onClick()}
        {...mobileProps}
      >
        {text}
      </Button>
    );
  }
);

export default DeviceAdaptiveActionButton;
